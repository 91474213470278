import p5 from 'p5';
import Grid from '../utils/grid';
import { Cell, GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

interface LineInterface extends Cell {
  angle: number;
}

const targetAngle = [45, 135];

class Line {
  sketch;
  x;
  y;
  width;
  height;
  angle;
  targetAngle;
  rowIndex;
  columnIndex;
  shouldAnimate;
  step;

  constructor(
    sketch: p5,
    { x, y, width, height, angle, rowIndex, columnIndex }: LineInterface
  ) {
    this.sketch = sketch;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.angle = angle;
    this.targetAngle =
      angle === targetAngle[0] ? targetAngle[1] : targetAngle[0];
    this.rowIndex = rowIndex;
    this.columnIndex = columnIndex;
    this.shouldAnimate = false;
    this.step = 10;
  }

  create() {
    this.sketch.noStroke();
    this.sketch.push();
    this.sketch.translate(this.x + this.width / 2, this.y + this.height / 2);
    this.sketch.rotate(this.angle);
    this.sketch.fill(0);
    this.sketch.rect(0, 0, 1, this.sketch.sqrt(2) * this.height);
    this.sketch.pop();
  }

  show() {
    if (!this.shouldAnimate) return;

    if (this.angle > this.targetAngle) {
      this.angle -= this.step;
    } else if (this.angle < this.targetAngle) {
      this.angle += this.step;
    }

    if (this.angle === this.targetAngle) {
      this.stop();
    }
  }

  start() {
    this.shouldAnimate = true;
  }

  stop() {
    this.shouldAnimate = false;
    this.targetAngle =
      this.angle === targetAngle[0] ? targetAngle[1] : targetAngle[0];
  }
}

class Maze {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    let lines: Line[] = [];

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);
        sketch.angleMode(sketch.DEGREES);
        sketch.rectMode(sketch.CENTER);

        new InView(element, sketch);

        new Grid(grid).draw((cell: Cell) => {
          const angle = Math.random() >= 0.5 ? targetAngle[0] : targetAngle[1];
          const line = new Line(sketch, { ...cell, angle });
          line.create();
          lines.push(line);
        });
      };

      sketch.draw = () => {
        sketch.background(255);

        if (sketch.frameCount % 5 === 0) {
          const randomIndex = Math.floor(
            sketch.random(grid.rows * grid.columns)
          );
          lines[randomIndex].start();
        }

        lines.map((line) => {
          line.create();
          line.show();
        });
      };
    }, element);
  }
}

export default Maze;
