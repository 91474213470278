import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

class Box {
  y;
  x;
  posY;
  width;
  height;
  angle;
  speed;
  shouldAnimate;
  sketch;

  constructor(x: number, y: number, width: number, height: number, sketch: p5) {
    this.y = y;
    this.x = x;
    this.width = width;
    this.height = height;
    this.angle = sketch.random(180);
    this.sketch = sketch;
    this.speed = sketch.random(0.5, 1.5);
    this.shouldAnimate = false;
    this.posY = 0;
  }

  create() {
    this.sketch.fill(255);
    this.sketch.rect(this.x, this.y, this.width, this.height);
  }

  move() {
    if (!this.shouldAnimate) return;

    this.sketch.push();
    this.angle += this.sketch.cos(this.angle / (10 * this.speed));
    this.posY += 1;
    this.sketch.translate(
      this.x,
      this.sketch.height +
        this.height -
        this.sketch.sin(this.posY) * this.speed * this.sketch.height
    );
    this.sketch.rotate(this.angle);
    this.sketch.rect(0, 0, this.width, this.height);
    this.sketch.pop();

    if (this.sketch.sin(this.posY) <= -1) {
      this.stop();
    }
  }

  start() {
    this.shouldAnimate = true;
  }

  stop() {
    this.shouldAnimate = false;
    this.angle = 0;
    this.posY = 0;
  }
}

class Popcorn {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    let boxes: Box[] = [];
    const boxAmount = 30;

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);
        sketch.angleMode(sketch.DEGREES);

        sketch.rectMode(sketch.CENTER);

        new InView(element, sketch);

        [...Array(boxAmount)].forEach(() => {
          const boxSize = sketch.random(25, 180);
          const box = new Box(
            sketch.random(sketch.width),
            0,
            boxSize,
            boxSize,
            sketch
          );
          box.create();
          boxes.push(box);
        });
      };

      sketch.draw = () => {
        sketch.background(255);

        if (sketch.frameCount % 5 === 0) {
          const randomIndex = Math.floor(sketch.random(boxAmount - 1));
          boxes[randomIndex].start();
        }

        boxes.forEach((box) => {
          box.move();
        });
      };
    }, element);
  }
}

export default Popcorn;
