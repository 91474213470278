import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

class Brick {
  y;
  x;
  width;
  height;
  speed;
  sketch;

  constructor(x: number, y: number, width: number, height: number, sketch: p5) {
    this.y = y;
    this.x = x;
    this.width = width;
    this.height = height;
    const direction = sketch.random() > 0.5 ? -1 : 1;
    this.speed = sketch.random(2.5, 5) * direction;
    this.sketch = sketch;
  }

  create() {
    this.sketch.noStroke();
    this.sketch.fill(255);
    this.sketch.rect(this.x, this.y, this.width, this.height);
  }

  move() {
    this.x += this.speed;
    if (this.x + this.width >= this.sketch.width || this.x <= 0) {
      this.speed *= -1;
    }
  }
}

const createBars = (sketch: p5) => {
  let len = 12;
  for (let i = 0; i < sketch.width / len; i++) {
    sketch.fill(255);
    if (i % 2 == 0) sketch.rect(i * len, sketch.height, 22, -sketch.height);
  }
};

class Stepper {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    let bricks: Brick[] = [];

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        const amountOfSteppers = 10;
        const brickWidth = 100;
        const brickHeight = sketch.height / amountOfSteppers;

        [...Array(amountOfSteppers)].forEach((_, index) => {
          bricks.push(
            new Brick(
              sketch.random(sketch.width - brickWidth),
              brickHeight * index,
              100,
              brickHeight,
              sketch
            )
          );
        });

        new InView(element, sketch);
      };

      sketch.draw = () => {
        sketch.background(0);
        createBars(sketch);

        bricks.forEach((brick) => {
          brick.create();
          brick.move();
        });
      };
    }, element);
  }
}

export default Stepper;
