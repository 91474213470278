import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

type Box = { x: number; y: number; z: number; speed: number };

class FloatingCubes {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    const boxesCount = 25;
    const boxes: Box[] = [];
    let noiseZ = 0;

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height, sketch.WEBGL);

        new InView(element, sketch);

        [...Array(boxesCount)].forEach(() => {
          boxes.push({
            x: sketch.random(-sketch.width / 2, sketch.width / 2),
            y: sketch.random(-sketch.height / 2, sketch.height / 2),
            z: sketch.random(-sketch.width, sketch.width),
            speed: sketch.random(4, 7),
          });
        });
      };

      sketch.draw = () => {
        sketch.background(255);
        sketch.pointLight(255, 255, 255, 0, -sketch.height / 2, sketch.width);
        sketch.pointLight(
          255,
          255,
          255,
          sketch.width / 2,
          -sketch.height / 2,
          sketch.width
        );
        sketch.pointLight(255, 255, 255, -sketch.width / 2, 0, sketch.width);
        sketch.ambientMaterial(255);

        const fieldOfView = sketch.PI / 3;
        const cameraZ = sketch.height / 2 / sketch.tan(fieldOfView / 2);

        boxes.forEach((box, index) => {
          const scale = sketch.noise(index, index, noiseZ) * 2;
          const odd = index % 2 === 0;
          const boxWidth = odd ? 150 : 150 * scale;
          const boxHeight = !odd ? 150 : 150 * scale;
          const boxDepth = !odd ? boxHeight / 2 : boxWidth / 2;

          sketch.camera(
            sketch.width / 2,
            -sketch.height / 4,
            sketch.height / 2 / sketch.tan(sketch.PI / 6),
            0,
            0,
            0,
            0,
            1,
            0
          );

          sketch.translate(0, 0, 0);
          sketch.perspective(
            fieldOfView,
            sketch.width / sketch.height,
            cameraZ / 1.2,
            sketch.width * 1.6
          );

          sketch.translate(box.x, box.y, -box.z);
          sketch.box(boxWidth, boxHeight, boxDepth);

          box.z += box.speed;
          noiseZ += 0.001;

          if (box.z > sketch.width) {
            box.x = sketch.random(-sketch.width / 2, sketch.width / 2);
            box.y = sketch.random(-sketch.height / 2, sketch.height / 2);
            box.z = box.z * -1;
          }
        });
      };
    }, element);
  }
}

export default FloatingCubes;
