import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

interface PointInterface {
  x: number;
  y: number;
}

class Point {
  sketch;
  x;
  y;

  constructor(sketch: p5, { x, y }: PointInterface) {
    this.sketch = sketch;
    this.x = x;
    this.y = y;
  }

  create() {
    this.sketch.stroke(this.sketch.random(50, 200));
    this.sketch.strokeWeight(1);
    let px = this.sketch.map(this.x, -2.182, 2.6558, 0, this.sketch.width);
    let py = this.sketch.map(this.y, 0, 9.9983, this.sketch.height, 0);

    this.sketch.point(px, py);
    this.nextPoint();
  }

  nextPoint() {
    let nextX;
    let nextY;
    let randomChance = this.sketch.random(1);

    if (randomChance <= 0.01) {
      nextY = 0.16 * this.y;
      nextX = 0;
    } else if (randomChance <= 0.85) {
      nextX = 0.85 * this.x + 0.04 * this.y;
      nextY = -0.04 * this.x + 0.85 * this.y + 1.6;
    } else if (randomChance <= 0.92) {
      nextX = 0.2 * this.x + -0.26 * this.y;
      nextY = 0.23 * this.x + 0.22 * this.y + 1.6;
    } else {
      nextX = -0.15 * this.x + 0.28 * this.y;
      nextY = 0.26 * this.x + 0.24 * this.y + 0.44;
    }

    this.x = nextX;
    this.y = nextY;
  }
}

class Verne {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    let point: Point;
    const pointPerDraw = [...Array(200)];

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        new InView(element, sketch);
        point = new Point(sketch, { x: 0, y: 0 });
        point.create();
      };

      sketch.draw = () => {
        pointPerDraw.forEach(() => point.create());
      };
    }, element);
  }
}

export default Verne;
