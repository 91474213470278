import p5 from 'p5';
import Grid from '../utils/grid';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

type Point = { x: number; y: number };
type Line = Point[] | [];
type Row = Line[] | [];

class CurvedLines {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    let context: CanvasRenderingContext2D;
    let noiseZ = 0;

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        new InView(element, sketch);

        const canvas = document.querySelector(
          '.painting--curved-lines canvas'
        ) as HTMLCanvasElement;
        context = canvas.getContext('2d') as CanvasRenderingContext2D;
      };

      sketch.draw = () => {
        let rows: Row = [];
        let line: Line = [];
        let noiseX = 0;
        let noiseY = 0;

        new Grid(grid).draw(({ y, height, width, columnIndex, rowIndex }) => {
          const distanceToCenter = Math.abs(
            width * columnIndex - grid.width / 2
          );

          const variance = Math.max(grid.width / 2 - 50 - distanceToCenter, 0);
          const random =
            ((sketch.noise(noiseX, noiseY, noiseZ) * variance) / 2) * -1;
          const point: Point = {
            x: width * Math.min(1, columnIndex) + width * columnIndex,
            y: y + height / 2 + random,
          };

          noiseX = 0.1 * columnIndex;
          noiseY = 0.1 * rowIndex;
          noiseZ += 0.000003;

          line = [...line, point];

          if (columnIndex === grid.columns - 1) {
            rows = [...rows, line];
            line = [];
          }
        });

        sketch.background(255);

        rows.forEach((line) => {
          context.lineWidth = 1;
          context.beginPath();
          context.moveTo(line[0].x, line[0].y);

          for (let i = 0; i < line.length - 2; i++) {
            const xc = (line[i].x + line[i + 1].x) / 2;
            const yc = (line[i].y + line[i + 1].y) / 2;
            context.quadraticCurveTo(line[i].x, line[i].y, xc, yc);
          }

          context.quadraticCurveTo(
            line[line.length - 1].x,
            line[line.length - 1].y,
            line[line.length - 1].x,
            line[line.length - 1].y
          );

          context.stroke();
        });
      };
    }, element);
  }
}

export default CurvedLines;
