import p5 from 'p5';
import Grid from '../utils/grid';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';
import Particle from '../utils/particle';

class PerlinWeb {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    const canvasGrid = new Grid(grid);
    let noiseX = 0;
    let noiseY = 0;
    let noiseZ = 0;
    let particles: Particle[] = [];
    let flowField: p5.Vector[] | [] = [];

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        new InView(element, sketch);

        sketch.background(255);
        particles = [...Array(1000)].map(() => new Particle(sketch));
      };

      sketch.draw = () => {
        canvasGrid.draw(({ columnIndex, rowIndex }) => {
          const angle =
            sketch.noise(noiseX, noiseY, noiseZ) * sketch.TWO_PI * 5;
          const vector = p5.Vector.fromAngle(angle);
          vector.setMag(1);
          const flowFieldIndex = columnIndex + rowIndex * grid.columns;
          flowField[flowFieldIndex] = vector;

          noiseX = 0.1 * columnIndex;
          noiseY = 0.1 * rowIndex;
          noiseZ += 0.000003;
        });

        particles.forEach((particle, index) => {
          particle.follow(flowField, grid);
          particle.update();
          particle.edges();
          particle.show(({ position, previousPosition }) => {
            sketch.stroke(0, 5);
            sketch.strokeWeight(1);
            sketch.line(
              position.x,
              position.y,
              previousPosition.x,
              previousPosition.y
            );
          });
        });
      };
    }, element);
  }

  setup() {}
}

export default PerlinWeb;
