import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

class RotatingRects {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        new InView(element, sketch);
      };

      sketch.draw = () => {
        sketch.background(255);
        let boxSize = 600;
        let scale = 0.95;
        const angle = sketch.sin(sketch.frameCount * 0.03) * 45;

        sketch.angleMode(sketch.DEGREES);
        sketch.translate(sketch.width / 2, sketch.height / 2);
        sketch.rectMode(sketch.CENTER);

        [...Array(110)].forEach((box, index) => {
          sketch.rotate(angle);
          sketch.rect(0, 0, boxSize, boxSize);
          boxSize *= scale;
        });
      };
    }, element);
  }
}

export default RotatingRects;
