import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

class DayAndNight {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    let angle = 0;

    const drawLine = (sketch: p5) => {
      const rotations = 2;
      angle = angle >= 360 * rotations ? angle * -1 : angle;
      angle += 90 / 100;
      const color = angle < 0 ? 255 : 0;

      sketch.translate(sketch.width / 2, sketch.height / 2);
      sketch.rotate(angle);
      sketch.fill(color);
      sketch.noStroke();
      sketch.rect(0, 0, 1, sketch.sqrt(2) * sketch.width);
    };

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);
        sketch.angleMode(sketch.DEGREES);
        sketch.rectMode(sketch.CENTER);

        new InView(element, sketch);

        drawLine(sketch);
      };

      sketch.draw = () => {
        drawLine(sketch);
      };
    }, element);
  }
}

export default DayAndNight;
