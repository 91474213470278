import { Cell } from './grid.types';

class Grid {
  width;
  height;
  rows;
  columns;

  constructor({ width = 400, height = 400, rows = 40, columns = 40 } = {}) {
    this.width = width;
    this.height = height;
    this.rows = rows;
    this.columns = columns;
  }

  draw(callback: (cell: Cell) => void) {
    const cell = {
      width: this.width / this.columns,
      height: this.height / this.rows,
    };
    const rows = [...new Array(this.rows)];
    const columns = [...new Array(this.columns)];

    rows.forEach((column, rowIndex) => {
      columns.forEach((row, columnIndex) => {
        callback({
          x: cell.width * columnIndex,
          y: cell.height * rowIndex,
          width: cell.width,
          height: cell.height,
          columnIndex,
          rowIndex,
        });
      });
    });
  }
}

export default Grid;
