import p5 from 'p5';
import Grid from '../utils/grid';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

class PerlinFlowfield {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    const canvasGrid = new Grid({ ...grid, columns: 20, rows: 20 });
    let noiseX = 0;
    let noiseY = 0;
    let noiseZ = 0;

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);
        new InView(element, sketch);
      };

      sketch.draw = () => {
        sketch.background(255);

        canvasGrid.draw(({ x, y, width, height, columnIndex, rowIndex }) => {
          const angle =
            sketch.noise(noiseX, noiseY, noiseZ) * sketch.TWO_PI * 2;
          const vector = p5.Vector.fromAngle(angle);

          noiseX = 0.1 * columnIndex;
          noiseY = 0.1 * rowIndex;
          noiseZ += 0.000003;

          sketch.push();
          sketch.translate(x + width, y + height / 2);
          sketch.rotate(vector.heading());
          sketch.line(0, 0, width, 0);
          sketch.pop();
        });
      };
    }, element);
  }
}

export default PerlinFlowfield;
