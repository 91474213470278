import p5 from 'p5';
import Grid from '../utils/grid';
import { GridProps } from '../utils/grid.types';

type Point = { x: number; y: number };
type Line = Point[] | [];
type Row = Line[] | [];

class Mash {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    const newGrid = { ...grid, rows: 10, columns: 10 };

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        let rows: Row = [];
        let line: Line = [];

        new Grid(newGrid).draw(
          ({ x, y, height, width, columnIndex, rowIndex }) => {
            const oddRow = rowIndex % 2 === 1;
            const point = {
              x:
                x +
                (oddRow ? width / 2 : 0) +
                width / 4 +
                (Math.random() * 0.8 - 0.4) * height,
              y:
                y +
                (oddRow ? height : 0) +
                (Math.random() * 0.8 - 0.4) * height,
            };

            line = [...line, point];

            if (columnIndex === newGrid.columns - 1) {
              rows = [...rows, line];
              line = [];
            }
          }
        );

        rows.forEach((row, index) => {
          const oddRow = index % 2 === 0;
          let dotLine = [];
          // console.log(row);
          for (let i = 0; i < row.length; i++) {
            dotLine.push(
              oddRow ? row[i] : rows[Math.min(index + 1, rows.length - 1)][i]
            );
            dotLine.push(
              oddRow ? rows[Math.min(index + 1, rows.length - 1)][i] : row[i]
            );
          }

          for (let i = 0; i < dotLine.length - 2; i++) {
            sketch.fill(sketch.random(255));
            sketch.triangle(
              dotLine[i].x,
              dotLine[i].y,
              dotLine[i + 1].x,
              dotLine[i + 1].y,
              dotLine[i + 2].x,
              dotLine[i + 2].y
            );
          }
        });
      };
    }, element);
  }
}

export default Mash;

/* var canvas = document.querySelector('canvas');
var context = canvas.getContext('2d');

var size = window.innerWidth;
var dpr = window.devicePixelRatio;
canvas.width = size * dpr;
canvas.height = size * dpr;
context.scale(dpr, dpr);
context.lineJoin = 'bevel';

var line, dot,
    odd = false,
    lines = [],
    gap = size / 8;

for(var y = gap / 2; y <= size; y+= gap) {
  odd = !odd;
  line = [];
  for(var x = gap / 4; x <= size; x+= gap) {
    dot = {x: x + (odd ? gap/2 : 0), y: y};
    line.push({
      x: x + (Math.random()*.8 - .4) * gap  + (odd ? gap/2 : 0),
      y: y + (Math.random()*.8 - .4) * gap
    });
    context.fill();
  }
  lines.push(line);
}

function drawTriangle(pointA, pointB, pointC) {
  context.beginPath();
  context.moveTo(pointA.x, pointA.y);
  context.lineTo(pointB.x, pointB.y);
  context.lineTo(pointC.x, pointC.y);
  context.lineTo(pointA.x, pointA.y);
  context.closePath();
  var gray = Math.floor(Math.random()*16).toString(16);
  context.fillStyle = '#' + gray + gray + gray;
  context.fill();
  context.stroke();
}

var dotLine;
odd = true;

for(var y = 0; y < lines.length - 1; y++) {
  odd = !odd;
  dotLine = [];
  for(var i = 0; i < lines[y].length; i++) {
    dotLine.push(odd ? lines[y][i]   : lines[y+1][i]);
    dotLine.push(odd ? lines[y+1][i] : lines[y][i]);
  }
  for(var i = 0; i < dotLine.length - 2; i++) {
    drawTriangle(dotLine[i], dotLine[i+1], dotLine[i+2]);
  }
}
 */
