import CurvedLines from './curved-lines';
import DayAndNight from './day-and-night';
import Popcorn from './popcorn';
import FloatingCubes from './floating-cubes';
import Mash from './mash';
import Maze from './maze';
import PerlinFlowField from './perlin-flow-field';
import PerlinWeb from './perlin-web';
import RectTree from './rect-tree';
import RotatingRects from './rotating-rects';
import Stepper from './stepper';
import { GridProps } from './utils/grid.types';
import Verne from './verne';

document.addEventListener('DOMContentLoaded', () => {
  const grid: GridProps = {
    width: 600,
    height: 600,
    rows: 30,
    columns: 30,
  };

  new DayAndNight(grid, document.querySelector('.painting--day-and-night'));
  new Maze(grid, document.querySelector('.painting--maze'));
  new CurvedLines(grid, document.querySelector('.painting--curved-lines'));
  new RotatingRects(grid, document.querySelector('.painting--rotating-rects'));
  new PerlinWeb(grid, document.querySelector('.painting--perlin-web'));
  new PerlinFlowField(
    grid,
    document.querySelector('.painting--perlin-flow-field')
  );
  new Mash(grid, document.querySelector('.painting--mash'));
  new FloatingCubes(grid, document.querySelector('.painting--floating-cubes'));
  new RectTree(grid, document.querySelector('.painting--rect-tree'));
  new Popcorn(grid, document.querySelector('.painting--popcorn'));
  new Stepper(grid, document.querySelector('.painting--stepper'));
  new Verne(grid, document.querySelector('.painting--verne'));
});
