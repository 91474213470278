import p5 from 'p5';
import { GridProps } from '../utils/grid.types';
import InView from '../utils/in-view';

let noiseX = 0;
let noiseY = 0;
let noiseZ = 0;

function drawRectangles(
  numberOfRectangles: number,
  x: number,
  y: number,
  width: number,
  height: number,
  angle: number,
  reductionFactor: number,
  twist: number,
  sketch: p5
) {
  if (numberOfRectangles > 0) {
    const sin = sketch.sin(sketch.frameCount);
    const reduction = sketch.map(sin, -1, 1, -0.02, 0.01) + reductionFactor;

    const rightBranch = sketch.noise(0, 0, noiseZ) * twist;
    const leftBranch = sketch.noise(noiseX, noiseY, 0) * twist;
    noiseX += 0.000005;
    noiseY += 0.000005;
    noiseZ += 0.000005;

    sketch.push();
    sketch.translate(x, y);
    sketch.rotate(angle);
    sketch.rect(0, 0, width, height);

    drawRectangles(
      numberOfRectangles - 1,
      width / 2,
      -width / 2,
      width * reductionFactor,
      width * reductionFactor,
      sin * rightBranch,
      reduction,
      twist,
      sketch
    );

    drawRectangles(
      numberOfRectangles - 1,
      -width / 2,
      -width / 2,
      width * reductionFactor,
      width * reductionFactor,
      -sin * leftBranch,
      reduction,
      twist,
      sketch
    );
    sketch.pop();
  }
}

class RectTree {
  constructor(grid: GridProps, element: HTMLElement | null) {
    if (!element) return;

    const boxSize = 150;
    const numberOfRectangles = 10;
    const reductionFactor = 0.7;
    const twist = 90;

    new p5((sketch: p5) => {
      sketch.setup = () => {
        sketch.createCanvas(grid.width, grid.height);

        new InView(element, sketch);

        sketch.angleMode(sketch.DEGREES);
        sketch.rectMode(sketch.CENTER);

        drawRectangles(
          numberOfRectangles,
          sketch.width / 2,
          sketch.height - 300,
          boxSize,
          300,
          0,
          reductionFactor,
          twist,
          sketch
        );
      };

      sketch.draw = () => {
        sketch.background(255);
        drawRectangles(
          numberOfRectangles,
          sketch.width / 2,
          sketch.height - 300 / 2,
          boxSize,
          300,
          0,
          reductionFactor,
          twist,
          sketch
        );
      };
    }, element);
  }
}

export default RectTree;
