import p5 from 'p5';

class InView {
  element;
  sketch;

  constructor(element: Element, sketch: p5) {
    this.element = element;
    this.sketch = sketch;
    this.setEventListeners();
    this.observe();
  }

  setEventListeners() {
    window.addEventListener('scroll', () => this.observe());
    this.observe();
  }

  observe() {
    const bounding = this.element.getBoundingClientRect();
    const isInView = bounding.bottom >= 0 && bounding.top <= window.innerHeight;

    if (isInView) {
      this.sketch.loop();
    } else {
      this.sketch.noLoop();
    }
  }
}

export default InView;
