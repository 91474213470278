import p5, { Vector } from 'p5';
import { GridProps } from './grid.types';

interface ParticleInterface {
  position: p5.Vector;
  previousPosition: p5.Vector;
  velocity: p5.Vector;
  acceleration: p5.Vector;
  maxSpeed: number;
  p5: p5;
}

class Particle implements ParticleInterface {
  position: p5.Vector;
  previousPosition: p5.Vector;
  velocity: p5.Vector;
  acceleration: p5.Vector;
  maxSpeed: number;
  p5: p5;

  constructor(sketch: p5) {
    this.p5 = sketch;
    this.position = this.p5.createVector(
      this.p5.random(this.p5.width),
      this.p5.random(this.p5.height)
    );
    this.velocity = this.p5.createVector(0, 0);
    this.acceleration = this.p5.createVector(0, 0);
    this.maxSpeed = 3;
    this.previousPosition = this.position.copy();
  }

  update() {
    this.velocity.add(this.acceleration);
    this.velocity.limit(this.maxSpeed);
    this.position.add(this.velocity);
    this.acceleration.mult(0);
  }

  applyForce(force: p5.Vector) {
    this.acceleration.add(force);
  }

  show(
    callback: (positions: {
      position: Vector;
      previousPosition: Vector;
    }) => void
  ) {
    callback({
      position: this.position,
      previousPosition: this.previousPosition,
    });
    this.updatePreviousPosition();
  }

  follow(vectors: p5.Vector[], grid: GridProps) {
    const x = Math.floor(this.position.x / (this.p5.width / grid.columns));
    const y = Math.floor(this.position.y / (this.p5.height / grid.rows));
    const index = x + y * grid.columns;
    const force = vectors[index];
    this.applyForce(force);
  }

  updatePreviousPosition() {
    this.previousPosition.x = this.position.x;
    this.previousPosition.y = this.position.y;
  }

  edges() {
    if (this.position.x > this.p5.width) {
      this.position.x = 0;
      this.updatePreviousPosition();
    }
    if (this.position.x < 0) {
      this.position.x = this.p5.width;
      this.updatePreviousPosition();
    }
    if (this.position.y > this.p5.height) {
      this.position.y = 0;
      this.updatePreviousPosition();
    }
    if (this.position.y < 0) {
      this.position.y = this.p5.height;
      this.updatePreviousPosition();
    }
  }
}

export default Particle;
